import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      suggestedForYouId: null,
      valid: false,
      shops: [],
      cities: [],
      search: "",
      shopsSearchInput: null,
      citiesSearchInput: null,
      suggestedForYou: {
        startDate: null,
        endDate: null,
        cityId: null,
        shopIds: [],
        allCities: false,
        type: "SUGGESTED_FOR_YOU"
      },
      citiesIsLoading: false,
      shopsIsLoading: false,
      startDateMenu: false,
      endDateMenu: false
    };
  },
  created() {
    this.suggestedForYouId = this.$route.params.suggestedForYouId;
    let title =
      this.suggestedForYouId != null
        ? "Suggested for you details"
        : "Insert new Suggested for you";
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.suggestedForYouId != null) {
      ApiService.get(`api/recommended-shops`, `${this.suggestedForYouId}`)
        .then(response => {
          this.$log.debug("SuggestedForYou: ", response.data);
          this.suggestedForYou = response.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
    this.searchCities();
    this.searchShops();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    remove(item) {
      const index = this.suggestedForYou.shopIds.indexOf(item.id);
      if (index >= 0) this.suggestedForYou.shopIds.splice(index, 1);
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    searchShops() {
      // Items have already been loaded
      if (this.shops.length > 0) return;

      // Items have already been requested
      if (this.shopsIsLoading) return;

      this.shopsIsLoading = true;
      ApiService.query("api/shops/dropdown-list")
        .then(res => {
          this.shops = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.shopsIsLoading = false));
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveRecommendedShops(this.suggestedForYou);
      }
    },
    saveRecommendedShops(suggestedForYou) {
      this.loading = true;

      this.$log.debug(
        "Recommended shops id to be created: " + suggestedForYou.id
      );
      if (!suggestedForYou.allCities) suggestedForYou.cityId = suggestedForYou.cityId[0];
      if (suggestedForYou.id != null) {
        ApiService.put(`api/recommended-shops`, suggestedForYou)
          .then(response => {
            this.$log.debug("Recommended Shops changed: " + response);
            this.$emit("recommendedShopsSaved", "recommendedShopsSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      } else {
        ApiService.post(`api/recommended-shops`, suggestedForYou)
          .then(response => {
            this.$log.debug("Recommended Shops created: " + response);
            this.$emit("recommendedShopsSaved", "recommendedShopsSaved");
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters ( ["currentUserPersonalInfo"] ) ,
    ...mapGetters ( ["currentUser"] ) ,
    show : {
      get() {
        return this.value;
      } ,
      set(value) {
        if (!value) {
          this.$emit ( "input" , value );
        }
      }
    }
  }
};
